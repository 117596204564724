import { Modal, Form, DatePicker, Input, InputNumber, Alert, Button, Row, Col, Divider, message, Select, Table } from "antd";
import { Fragment, useState } from "react";
import { BiError, BiInfoCircle, BiTrash } from "react-icons/bi";
import moment from "moment";
import { actions, useCurOrganisation, useCurPerson, useProjects } from "../../../../utils/store";
import { monthToMoment, projectEnd, projectStart } from "../../../../utils/utils";

type Props = {
  visible: boolean
  onClose: () => any
}

export default function EmployeeAllocAddModal(props: Props) {
  const projs = useProjects();
  const curPerson = useCurPerson();
  const curOrg = useCurOrganisation();
  const [totalAlloc, setTotalAlloc] = useState(0);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<any>()

  if (!curPerson || !curOrg) return null;

  async function onSubmit(values: any) {
    if (!curPerson) return null;

    setLoading(true);
    try {
      await actions.persons.createAllocation({
        PersonID: curPerson.PersonID,
        Lon: values.lon,
        Start: values.start.toISOString(),
        Stilling: values.stilling,
        Activities: values.acts?.map((a: any) => ({
          ActivityID: a.actId,
          Alloc: a.alloc,
          Lines: a.descrs,
        })),
        Other: values.others?.map((o: any) => ({
          JournalNr: o.journal,
          Project: o.project,
          Alloc: o.alloc,
        }))
      });
      message.info("Kontrakten skal nu underskrives");
      props.onClose();
    }
    finally {
      setLoading(false);
    }
  }

  function onChange(c: any, values: any) {
    setValues(values);
    setTotalAlloc(
      (values.acts || []).reduce((p: number, v: any) => p + (v.alloc || 0), 0) +
      (values.others || []).reduce((p: number, v: any) => p + (v?.alloc || 0), 0)
    );
  }

  if (!projs) return null;

  const start = monthToMoment(curPerson.Start || curOrg.Start || projectStart);
  const end = monthToMoment(curOrg.End || projectEnd);
  let earliest = moment();
  if (earliest.date() > 1)
    earliest = earliest.month(earliest.month() + 1);

  return (
    <Modal width="1000px" title={`Tilføj allokering - ${curPerson.FullName}`} visible={props.visible} onCancel={props.onClose} footer={null}>
      <Form layout="vertical" onFinish={onSubmit} onValuesChange={onChange}>
        <Row gutter={10} align="bottom">
          <Col flex="0 0 50%">
            <Form.Item name="start" label="Startdato for allokeringen" rules={[{ required: true, message: "Påkrævet" }]}>
              <DatePicker disabled={loading} style={{ width: "100%" }} format="D. MMMM YYYY" defaultPickerValue={moment().date(1)}
                picker="month"
                disabledDate={v => v.isBefore(start) || v.isAfter(end) || v.isBefore(earliest)}
              />
            </Form.Item>
          </Col>
          <Col flex="0 0 50%">
            <Alert style={{ marginBottom: 20 }} type="warning" message={<>Allokeringskontrakten skal være underskrevet <strong>senest</strong> denne dato</>} showIcon icon={<BiError />} />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col flex="0 0 50%">
            <Form.Item name="stilling" label="Stillingsbetegnelse" rules={[{ required: true, message: "Påkrævet" }]}>
              <Input disabled={loading} />
            </Form.Item>
          </Col>
          <Col flex="0 0 50%">
            <Form.Item name="lon" label="Månedsløn" tooltip="Den pensionsgivende månedsløn" rules={[{ required: true, message: "Påkrævet" }]}>
              <InputNumber disabled={loading} addonAfter="kr." style={{ width: "100%" }} min={0} precision={2} decimalSeparator="," />
            </Form.Item>
          </Col>
        </Row>
        <Divider>Allokering i Fyrtårn</Divider>
        <Row justify="center">
          <Alert type="info" showIcon icon={<BiInfoCircle />} description="Her skal du angive arbejdsbeskrivelser og allokering for projekterne i Fyrtårnet" style={{ marginBottom: 10 }} />
        </Row>
        <div style={{ display: "grid", width: "100%", rowGap: 5, columnGap: 10, gridTemplateColumns: "25% 125px auto" }}>
          <div style={{ gridColumn: 1, gridRow: 1, textAlign: "left", width: "25%" }}>Projekt</div>
          <div style={{ gridColumn: 2, gridRow: 1, textAlign: "left", width: 150 }}>Allokering</div>
          <div style={{ gridColumn: 3, gridRow: 1, textAlign: "left" }}>Arbejdsbeskrivelser</div>
          {
            curOrg.Activities?.map((oa, i) => {
              const projAlias = oa.ActivityID.split(".")[0];

              const proj = projs.find(p => p.Alias === projAlias);
              if (!proj) return null;
              const act = proj.Activities?.find(a => a.ActivityID == oa.ActivityID)
              if (!act) return null;

              return (
                <Fragment key={i}>
                  <div style={{ gridColumn: 1, gridRow: i + 2 }}>
                    <Form.Item name={["acts", i, "actId"]} style={{ margin: 0 }} initialValue={oa.ActivityID} hidden></Form.Item>
                    <div style={{ border: "1px solid #d9d9d9", borderRadius: 2, padding: "0 11px" }}>
                      <Row style={{ lineHeight: "1.18" }}>{proj.Name}</Row>
                      <Row style={{ fontSize: "0.7rem", lineHeight: "1.18" }}>{act.Name}</Row>
                    </div>
                  </div>
                  <div style={{ gridColumn: 2, gridRow: i + 2 }}>
                    <Form.Item name={["acts", i, "alloc"]} style={{ margin: 0 }}>
                      <InputNumber disabled={loading} style={{ width: "100%" }} addonAfter="%" precision={2} min={0} step={1} decimalSeparator="," />
                    </Form.Item>
                  </div>
                  <div style={{ gridColumn: 3, gridRow: i + 2 }}>
                    <Form.Item name={["acts", i, "descrs"]} style={{ margin: 0 }}
                      rules={[{
                        validator: async (_, v) => {
                          if (!v && values.acts[i].alloc)
                            throw new Error("Der skal angives arbejdsbeskrivelser")
                        }
                      }]}
                    >
                      <Select className="arbejdsbskrivelser" disabled={loading} mode="tags" placeholder="Beskriv dine opgaver, eller vælg fra listen"
                        options={[...(proj.Workdescrs || []), ...(act.Workdescrs || [])].map(wd => ({ value: wd, label: wd }))} />
                    </Form.Item>
                  </div>
                </Fragment>
              )
            })
          }
        </div>
        {
          !curOrg.Activities?.length &&
          <Row justify="center" style={{ marginTop: 10, color: "rgba(0,0,0,0.6)" }}>
            <i>Din organisation deltager ikke i nogen aktiviteter</i>
          </Row>
        }

        <Divider>Andre allokeringer</Divider>
        <Row justify="center">
          <Alert type="info" showIcon icon={<BiInfoCircle />} description="Hvis du er allokeret i andre Strukturfondsprojekter, skal du angive allokeringerne her" style={{ marginBottom: 10 }} />
        </Row>

        <Form.List name="others">
          {(fields, { add, remove }) => (
            <>
              {
                fields.map(({ key, name, ...restFields }) =>
                  <Row key={key} justify="center" align="middle" gutter={10} >
                    <Col flex={1}>
                      <Row gutter={10}>
                        <Col flex="0 0 25%">
                          <Form.Item name={[name, "journal"]} {...restFields} label="Journalnummer" rules={[{ required: true, message: "Påkrævet" }]}>
                            <Input disabled={loading} />
                          </Form.Item>
                        </Col>
                        <Col flex="1 0 auto">
                          <Form.Item name={[name, "project"]} {...restFields} label="Projektnavn" rules={[{ required: true, message: "Påkrævet" }]}>
                            <Input disabled={loading} />
                          </Form.Item>
                        </Col>
                        <Col flex="0 0 120px">
                          <Form.Item name={[name, "alloc"]} {...restFields} label="Allokering" rules={[{ required: true, message: "Påkrævet" }]}>
                            <InputNumber disabled={loading} addonAfter="%" precision={2} decimalSeparator="," min={0} />
                          </Form.Item>
                        </Col>
                        <Col flex="0 0 20px" style={{ display: "flex", alignItems: "center", paddingTop: 5 }}>
                          <Button disabled={loading} style={{ padding: 0 }} size="small" type="link" danger onClick={() => remove(name)}><BiTrash /></Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )
              }
              {fields.length == 0 && <Row justify="center"><i>Ingen andre allokeringer</i></Row>}
              <Row justify="center" style={{ marginTop: 10 }}><Button disabled={loading} onClick={() => add()}>Tilføj anden allokering</Button></Row>
            </>
          )}
        </Form.List>

        <Divider />
        <Row justify="space-between">
          <Col>
            Samlet allokering: <strong style={{ color: (totalAlloc > 100) ? "red" : "black" }}>{totalAlloc}</strong>%
          </Col>
          <Col>
            <Button loading={loading} htmlType="submit" type="primary" disabled={totalAlloc === 0 || totalAlloc > 100}>Generér kontrakt</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}