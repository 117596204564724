import { Button, Col, Row, Table, TableColumnsType, Tabs, Tag } from "antd"
import { useState } from "react";
import { Link } from "react-router-dom";
import { useOrganisations, usePersons } from "../../../utils/store"
import InviteModal from "./components/InviteModal";

export default function Stamdata() {
  const orgs = useOrganisations()
  const pers = usePersons()
  const [showInvite, setShowInvite] = useState(false);

  if (!orgs || !pers) return null;


  const orgColumns: TableColumnsType<Organisation> = [
    {
      title: "Navn",
      dataIndex: "Name",
      render: (v, o) => <Link to={`/organisation/${o.CVR}`}>{v}</Link>
    },
    {
      title: "Deltageraftale",
      render: (v, o) => o.Aftale?.Signed ? <Tag color="green">Underskrevet</Tag> : <Tag color="orange">Mangler</Tag>
    }
  ]
  const persColumns: TableColumnsType<Person> = [
    {
      title: "Navn",
      dataIndex: "FullName",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Organisation",
      dataIndex: "OrganisationID",
      render: (v) => {
        if (!v) return null;
        const o = orgs.find(o => o.OrganisationID == v);
        if (!o) return null;

        return <Link to={`/organisation/${o.CVR}`}>{o.Name}</Link>
      }
    }
  ]

  return (
    <Col flex="0 1 1200px">
      <Tabs>
        <Tabs.TabPane key="orgs" tab="Organisationer" >
          <Table dataSource={orgs} columns={orgColumns} size="small" pagination={false} rowKey="OrganisationID" />
        </Tabs.TabPane>
        <Tabs.TabPane key="pers" tab="Personer" >
          <Table dataSource={pers} columns={persColumns} size="small" pagination={false} rowKey="PersonID" />
        </Tabs.TabPane>
      </Tabs>
      <Row justify="center" style={{ marginTop: 20 }}>
        <Button onClick={() => setShowInvite(true)}>Invitér</Button>
      </Row>
      <InviteModal visible={showInvite} onClose={() => setShowInvite(false)} />
    </Col>
  )
}
