import { Result } from "antd";


type Props = {
  title: string
}

export default function Error(props: Props) {
  return (
    <Result
      status="error"
      title={props.title}
      subTitle={
        <>
          Kontakt venligst support på <a href="mailto:fyrtaarn-support@cleancluster.dk">fyrtaarn-support@cleancluster.dk</a>
        </>
      } />
  )
}