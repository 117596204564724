import { Alert, Col, Row } from "antd";
import { BiCheck, BiCommentDetail, BiErrorCircle, BiLock, BiPen } from "react-icons/bi";
import { getLatestRegistrationLog } from "../../utils/utils";

type Props = {
    reg: Registration | null
}

export default function StatusAlert(props: Props) {
    if (props.reg == null) return null;
    const regLog = getLatestRegistrationLog(props.reg);

    let text: string;
    let type: string;
    let icon: JSX.Element;
    let extra: JSX.Element | null = null;

    switch (props.reg.Status) {
        case "rejected":
            text = "Registreringen er blevet afvist";
            type = "error"
            icon = <BiErrorCircle />;
            break;
        case "accepted":
            text = "Registreringen er godkendt"
            type = "success"
            icon = <BiCheck />;
            break;
        case "submitted":
            icon = <BiLock />
            text = "Registreringen er indsendt"
            type = "success"
            break;
        case "needs-signing":
            icon = <BiPen />
            type = "info"
            text = "Timeregistreringen er godkendt og skal underskrives"
            // TODO: Fix this
            // extra = <Row justify="center"><Button disabled={!props.reg.timeseddel?.signLink} target="_blank" href={props.reg.timeseddel?.signLink!} style={{ margin: "10px 0 0 -48px" }} type="primary">Underskriv nu</Button></Row>

            break;
        default: return null
    }

    return (
        <Alert
            style={{ marginBottom: 20 }}
            icon={icon} showIcon
            message={<Row style={{ fontWeight: 600 }}>{text}</Row>}
            description={
                regLog?.Note
                    ?
                    <>
                        <Row align="top" style={{ flexWrap: "nowrap" }} gutter={5}>
                            <Col><BiCommentDetail style={{ marginTop: 5 }} /> </Col>
                            <Col>{regLog.Note}</Col>
                        </Row>
                        {extra}
                    </>
                    : extra
            }
            type={type as any}
        />
    )
}