import { Form, Input, message, Modal, Result } from "antd";
import { useState } from "react";
import { actions, useCurMonth, useCurPerson } from "../../../../utils/store";

type Props = {
  visible: boolean
  onClose: () => any
}

export default function RegContactModal(props: Props) {
  const curPerson = useCurPerson();
  const curMonth = useCurMonth();

  const [contactForm] = Form.useForm();
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);

  async function onContact() {
    if (!curPerson || !curMonth) return;

    setLoading(true);
    contactForm.validateFields();
    const values = contactForm.getFieldsValue();

    await actions.registrations.createTicket({
      Subject: values.subject,
      Message: values.message,
      PersonID: curPerson.PersonID,
      Month: curMonth,
    })
      .then(() => {
        message.success("Beskeden blev sendt");
        setSent(true);
      });

    setLoading(false);
  }

  return (
    <Modal title="Kontakt os" visible={props.visible} closable={!loading}
      cancelButtonProps={{ disabled: loading }} okButtonProps={{ disabled: sent, loading: loading }}
      okText="Send besked" cancelText="Luk" onCancel={() => { props.onClose(); setSent(false); }}
      onOk={onContact}>
      {
        sent ?
          <Result status="success" title="Beskeden er blevet sendt til support" subTitle="Din besked vil blive behandlet, og du vil modtage svar per e-mail" />
          :
          <>
            Hvis du ønsker at kontakte os ang. den pågældende registrering, kan du udfylde nedenstående:
            <Form form={contactForm} layout="vertical" style={{ marginTop: 20 }}>
              <Form.Item label="Emne" name="subject" rules={[{ required: true, message: "Påkrævet" }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Besked" name="message" rules={[{ required: true, message: "Påkrævet" }]}>
                <Input.TextArea style={{ height: 200 }} />
              </Form.Item>
            </Form>
          </>
      }
    </Modal>
  )
}