import { Alert, Button, message, Modal, Row } from "antd";
import { useState } from "react";
import { actions, useProjects, useRoles, useUser, useUserOrganisation } from "../../../../utils/store";
import SplashLayout from "../../../layouts/SplashLayout";
import UserLayout from "../../../layouts/UserLayout";
import Error from "../../../shared/Error";
import FakeSignButton from "../../../shared/FakeSignButton";
import Logo from "../../../shared/Logo";

export default function OrgSignDeMinimisModal() {
  const user = useUser();
  const roles = useRoles();
  const userOrg = useUserOrganisation();
  const projs = useProjects();
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);


  if (!user || !userOrg) return null;

  if (!userOrg.Deminimis.Signature?.SignLink) {
    return <Error title="Der er gået noget galt med signering af de minimis" />
  }

  async function onSend() {
    if (!userOrg) return null

    setLoading(true)
    await actions.organisations.sendDeminimis(userOrg.OrganisationID)
      .finally(() => { setLoading(false) })
    message.success(`Dokumentet er blevet sendt til underskrift til ${userOrg.Tegningsberettiget.Email}`)
    setSent(true)
  }

  const activities = userOrg.Activities?.filter(a => !a.DeminimisSigned) || [];

  return (
    <UserLayout>
      <SplashLayout>
        <Modal visible closable={false} footer={null}>
          <Row justify="center"><Logo /></Row>
          <Row justify="center">
            <Alert type="info" description={
              <>
                <Row>
                  Din organisation er tilføjet til ét eller flere projekter.
                  I den forbindelse skal der underskrives en de minimis erklæring.
                </Row>
                <p style={{ marginTop: 20 }}>
                  Det drejer sig om flg. projekter:
                  <ul>
                    {activities.map(oa => {
                      const projAlias = oa.ActivityID.split(".")[0];
                      const p = projs?.find(p => p.Alias == projAlias)
                      if (!p) return null;
                      const a = p.Activities?.find(a => a.ActivityID == oa.ActivityID)
                      if (!a) return null;

                      return <li><strong>{p.Name} - {a.Name}</strong></li>
                    })}
                  </ul>
                </p>

                {
                  userOrg.Tegningsberettiget.Email === user.Email
                    ? <>
                      <Row>Når de minimis erklæringen er underskrevet, kan du blot opdatere denne side</Row>
                      <Row justify="center" style={{ marginTop: 5 }} >
                        <Button type="primary" target="_blank" href={userOrg.Deminimis.Signature.SignLink}>Underskriv nu</Button>
                      </Row>
                    </>
                    :
                    <>
                      <Row style={{ marginTop: 20 }}>
                        Tryk på knappen nedenfor for at udsende dokumentet til underskrift. Dokumentet skal underskrives af den tegningsberettiget:
                      </Row>
                      <Row justify="center"><strong>{`${userOrg.Tegningsberettiget.Name} <${userOrg.Tegningsberettiget.Email}>`}</strong></Row>
                      <Row justify="center" style={{ marginTop: 20 }}>
                        <Button disabled={sent} loading={loading} onClick={onSend} type="primary">Udsend e-mail</Button>
                      </Row>
                    </>
                }

                {
                  roles.includes("test") && <FakeSignButton doc={userOrg.Deminimis.Signature} />
                }
              </>
            } />
          </Row>
        </Modal >
      </SplashLayout>
    </UserLayout>
  )
}