import { Select, Button, Cascader, Form, Input, Modal, Row, Tabs, Col, message } from "antd"
import { useForm } from "antd/lib/form/Form"
import { BaseSelectRef } from "rc-select"
import { useRef, useState } from "react"
import { actions, useProjects } from "../../../../utils/store"

type Props = {
  visible: boolean
  onClose: () => any
}

let reloadTimeout: any = null;

export default function InviteModal(props: Props) {
  const projs = useProjects();
  const [cvrOptions, setCvrOptions] = useState<any[]>([]);
  const [cvrLoading, setCvrLoading] = useState(false);
  const [pnoOptions, setPnoOptions] = useState<any[]>([]);
  const [pnoLoading, setPnoLoading] = useState(false);

  const [cvr, setCvr] = useState<string | null>(null);
  const [pno, setPno] = useState<string | null>(null);

  const [form] = useForm()
  const [loading, setLoading] = useState(false);

  function onCvrSearch(query: string) {
    if (!query) return;

    setCvrLoading(true)

    setCvr(null);
    setCvrOptions([]);
    setPno(null);
    setPnoOptions([]);

    clearTimeout(reloadTimeout);
    reloadTimeout = setTimeout(() => {
      actions.organisations.searchCVR(query)
        .then(r => setCvrOptions(r))
        .finally(() => setCvrLoading(false))
    }, 500);
  }

  function onCvrChange(cvr: string) {
    setCvr(cvr)

    if (cvr) {
      setPnoLoading(true);
      actions.organisations.listPUnits(cvr)
        .then(r => setPnoOptions(r))
        .finally(() => setPnoLoading(false));
    }
  }

  function onChange(d: any, values: any) {
    if (values.pno && !values.cvr) {
      form.setFieldsValue({ "pno": undefined })
    }
  }

  async function onSubmit(values: any) {
    if (!projs) return

    const acts = [];

    for (const act of (values.acts || [])) {
      if (act.length > 1)
        acts.push(act[1])
      else {
        const p = projs.find(p => p.Alias == act[0]);
        acts.push(...(p?.Activities?.map(a => a.ActivityID) || []))
      }
    }

    setLoading(true)
    try {
      await actions.organisations.invite({
        Pno: values.pno,
        Email: values.email,
        Afregning: values.afregning,
        Activities: acts,
      })
      message.success("Organisationen er blevet inviteret")
      props.onClose()
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <Modal visible={props.visible} onCancel={props.onClose} title="Invitér" footer={false}>
      <Form form={form} layout="vertical" onFinish={onSubmit} onValuesChange={onChange}>
        <Tabs destroyInactiveTabPane>
          <Tabs.TabPane key="regional" tab="Regionalfond">
            <Form.Item name="cvr" label="Organisation" rules={[{ required: true, message: "Påkrævet" }]}>
              <Select
                showSearch
                placeholder="Søg på organisation"
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={onCvrSearch}
                onChange={onCvrChange}
                notFoundContent={null}
                loading={cvrLoading}
                optionLabelProp="title"
                style={{ width: "100%" }}
                value={cvr || undefined}
                disabled={loading}
              >
                {
                  cvrOptions.map(o =>
                    <Select.Option key={o.CVR} title={
                      <Row justify="space-between"><Col style={{ fontWeight: 600 }}>{o.Name}</Col><Col>CVR: {o.CVR}</Col></Row>
                    }>
                      <Row justify="space-between" style={{ lineHeight: 1.2 }}><Col style={{ fontWeight: 600 }}>{o.Name}</Col><Col style={{ fontSize: 12, }}>CVR: {o.CVR}</Col></Row>
                      <Row style={{ fontSize: 12, lineHeight: 1.2 }}>{o.Address}</Row>
                      <Row style={{ fontSize: 12, lineHeight: 1.2 }}>{o.Zip} {o.City}</Row>
                    </Select.Option>
                  )
                }
              </Select>
            </Form.Item>
            <Form.Item name="pno" label="P-enhed" rules={[{ required: true, message: "Påkrævet" }]}>
              <Select
                placeholder={"Vælg afdeling"}
                defaultActiveFirstOption={false}
                filterOption={false}
                notFoundContent={null}
                loading={pnoLoading}
                optionLabelProp="title"
                style={{ width: "100%" }}
                disabled={!cvr || loading}
                value={pno || undefined}
              >
                {
                  pnoOptions.map(o =>
                    <Select.Option key={o.Pno} title={
                      <Row justify="space-between"><Col style={{ fontWeight: 600 }}>{o.Name}</Col><Col>P-nr: {o.Pno}</Col></Row>
                    }>
                      <Row justify="space-between" style={{ lineHeight: 1.2 }}><Col style={{ fontWeight: 600 }}>{o.Name}</Col><Col style={{ fontSize: 12, }}>P-nr: {o.Pno}</Col></Row>
                      <Row style={{ fontSize: 12, lineHeight: 1.2 }}>{o.Address}</Row>
                      <Row style={{ fontSize: 12, lineHeight: 1.2 }}>{o.Zip} {o.City}</Row>
                    </Select.Option>
                  )
                }
              </Select>
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[{ required: true, message: "Påkrævet" }]}>
              <Input disabled={loading} type="email" />
            </Form.Item>
            <Form.Item name="afregning" label="Afregningsmetode" rules={[{ required: true, message: "Påkrævet" }]}>
              <Select disabled={loading} >
                <Select.Option key="faktisk-lon">Faktisk Løn</Select.Option>
                <Select.Option key="standardsats">Standardsats</Select.Option>
              </Select>
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane key="social" tab="Socialfond">
            <Form.Item name="email" label="Email" rules={[{ required: true, message: "Påkrævet" }]}>
              <Input disabled={loading} type="email" />
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
        <Form.Item name="acts" label="Aktiviteter">
          <Cascader expandTrigger="hover" multiple
            disabled={loading}
            displayRender={(labels) => labels.join(" - ")}
            options={projs?.map(p => ({
              value: p.Alias,
              label: p.Name,
              children: p.Activities?.map(a => ({
                value: a.ActivityID,
                label: a.Name
              }))
            }))} />
        </Form.Item>
        <Row justify="center">
          <Button htmlType="submit" type="primary" loading={loading} >Invitér</Button>
        </Row>
      </Form>
    </Modal>
  )
}