type Props = {
    light?: boolean
    size?: "large" | "medium" | "small"
}

export default function Logo(props: Props) {
    const sizes = {
        "large": 30,
        "medium": 24,
        "small": 16
    }

    return <h1 style={{
        color: props.light ? "white" : "black",
        fontSize: props.size ? sizes[props.size] : 16
    }}>Fyrtårn</h1>
}