import { Button, Col, Collapse, Descriptions, Dropdown, Menu, message, Popconfirm, Row, Table, TableColumnsType, Tag } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { BiCaretDown, BiDotsVertical, BiPen, BiPencil, BiTrash } from "react-icons/bi";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { actions, useCurOrganisation, useCurOrganisationEmployees, useOrganisations, useProjects } from "../../../utils/store";
import { formatMonth, getActivePersonAlloc, momentToMonth, monthToMoment, projectEnd, projectStart } from "../../../utils/utils";
import EmployeeAllocModal from "./components/EmpAllocModal";
import EmployeeInviteModal from "./components/EmpInviteModal";
import EmployeePeriodModal from "./components/EmpPeriodModal";

export default function OrganisationCVR() {
    const projs = useProjects();
    const orgs = useOrganisations();
    const curOrg = useCurOrganisation();
    const curEmps = useCurOrganisationEmployees();

    const [showAddModal, setShowAddModal] = useState(false);
    const [showPeriodModal, setShowPeriodModal] = useState(false);
    const [showAllocModal, setShowAllocModal] = useState(false);

    const params = useParams()
    const navigate = useNavigate();

    useEffect(() => {
        if (!orgs) return;

        const newOrg = orgs.find(o => o.CVR === params.cvr);
        if (newOrg) actions.selectOrganisation(newOrg.OrganisationID);
        else navigate(`/organisation`);

    }, [params.cvr, orgs])

    function onOrgSelect(i: any) {
        actions.selectOrganisation(i.key);
    }

    function onUninvite(p: Person) {
        actions.organisations.uninviteEmployee(p.PersonID)
            .then(() => { message.success(`${p.Email} er blevet fjernet`) });
    }

    async function onPeriodeClick(emp: Person) {
        await actions.selectPerson(emp.PersonID);
        setShowPeriodModal(true);
    }

    async function onAllocClick(emp: Person) {
        await actions.selectPerson(emp.PersonID);
        setShowAllocModal(true);
    }

    let columns: TableColumnsType<Person> = [
        {
            title: "Navn",
            render: (v, o) => o.Complete ? <Link to={`/registrering/${o.Email}`}>{o.FullName}</Link> : "-"
        },
        {
            title: "E-mail",
            dataIndex: "Email"
        },
        {
            title: "Periode",
            render: (v, o) => {
                const start = monthToMoment(o.Start || curOrg?.Start || projectStart);
                const end = monthToMoment(o.End || curOrg?.End || projectEnd);
                return (
                    <Row justify="space-between">
                        <Col>{start.format("MM/YYYY")} - {end.format("MM/YYYY")}</Col>
                        <Col><Button type="link" size="small" onClick={() => onPeriodeClick(o)} ><Row align="middle"><BiPencil /></Row></Button></Col>
                    </Row>
                )
            }
        },
        {
            title: "Status",
            dataIndex: "Complete",
            render: (v, o) => {
                const end = monthToMoment(o.End || curOrg?.End || projectEnd);
                return (
                    <Row align="middle" justify="space-between">
                        <Col>
                            {
                                v
                                    ? (
                                        (end.isBefore())
                                            ? <Tag>Afsluttet</Tag>
                                            : <Tag color="green">Aktiv</Tag>
                                    )
                                    : <Tag color="orange">Inviteret</Tag>
                            }
                        </Col>
                        <Col>
                            <Row align="middle">
                                {!o.Complete &&
                                    <Popconfirm title="Er du sikker?" okText="Ja" cancelText="Nej" onConfirm={() => onUninvite(o)}>
                                        <Button type="link" size="small" danger><BiTrash /></Button>
                                    </Popconfirm>
                                }
                            </Row>
                        </Col >
                    </Row >
                )
            }
        }
    ]

    if (curOrg?.Afregning === "faktisk-lon") {
        columns = [
            ...columns.slice(0, 3),
            {
                title: "Allokering",
                dataIndex: "allocs",
                render: (v, o) => {
                    if (!o.Complete) return "-";

                    const alloc = getActivePersonAlloc(momentToMonth(moment()))

                    return (
                        <Row justify="space-between" align="middle" gutter={10}>
                            <Col>
                                {alloc
                                    ? <>{alloc.Activities?.reduce((p, c) => p + c.Alloc, 0)}%</>
                                    : <i style={{ color: "#aaa" }}>ingen</i>
                                }
                            </Col>
                            <Col><Button type="link" size="small" onClick={() => onAllocClick(o)} ><Row align="middle"><BiPencil /></Row></Button></Col>
                        </Row>
                    )
                }
            },
            ...columns.slice(3)
        ];
    }

    let nameEl = <>{curOrg?.Name || "<ingen>"}</>;
    if (orgs && orgs.length > 1) {
        nameEl = (
            <>
                <Dropdown
                    trigger={["click"]}
                    overlay={
                        <Menu onClick={onOrgSelect} selectedKeys={curOrg ? [curOrg.OrganisationID] : []}>
                            {orgs.map(o => <Menu.Item key={o.OrganisationID}>{o.Name}</Menu.Item>)}
                        </Menu>
                    }
                >
                    <Row align="middle" style={{ display: "inline-flex", cursor: "pointer" }}>{nameEl} <BiCaretDown /></Row>
                </Dropdown>
            </>
        );
    }

    return (
        <Col flex="0 1 800px">
            <Row style={{ marginBottom: 20 }}>
                <Col flex={1}>
                    <Row style={{ fontSize: 16, fontWeight: 700 }}>{nameEl}</Row>
                </Col>
            </Row>

            <Row style={{ marginBottom: 20 }}>

                <Col flex={1} >
                    <Collapse style={{ fontSize: "0.75rem" }}>
                        <Collapse.Panel header="Detaljer om organisationen" key={1}>
                            <Descriptions bordered size="small">
                                <Descriptions.Item label="CVR-nr.">{curOrg?.CVR || "<mangler>"}</Descriptions.Item>
                                <Descriptions.Item label="P-nr.">{curOrg?.Pno || "<mangler>"}</Descriptions.Item>
                                <Descriptions.Item label="Afregning">{curOrg?.Afregning || "<mangler>"}</Descriptions.Item>
                                <Descriptions.Item label="Aftale">{
                                    curOrg?.Aftale?.Signed
                                        ? <Tag color="green">Underskrevet</Tag>
                                        : <Tag color="orange">Mangler</Tag>}
                                </Descriptions.Item>
                                <Descriptions.Item label="Start">{formatMonth(curOrg?.Start || projectStart)}</Descriptions.Item>
                                <Descriptions.Item label="Slut">{formatMonth(curOrg?.End || projectEnd)}</Descriptions.Item>
                                <Descriptions.Item label="Aktiviteter" style={{ verticalAlign: "top" }}>
                                    {curOrg && curOrg.Activities?.map(pa => {
                                        const projAlias = pa.ActivityID.split(".")[0];
                                        const proj = projs?.find(p => p.Alias === projAlias);
                                        if (!proj) return null;
                                        const act = proj.Activities?.find(a => a.ActivityID == pa.ActivityID)
                                        if (!act) return null;

                                        return (
                                            <Row justify="space-between" align="middle" key={pa.ActivityID} style={{ borderBottom: "1px solid #eee" }}>
                                                <Col>{proj.Name}{act.Name && ` - ${act.Name}`}</Col>
                                                <Col style={{ padding: "7px 0" }}>
                                                    {pa.DeminimisSigned
                                                        ? (
                                                            act.DeminimisEst == 0
                                                                ? <Tag>De minimis ikke relevant</Tag>
                                                                : <Tag color="green">De minimis underskrevet</Tag>
                                                        )
                                                        : <Tag color="red">De minimis mangler</Tag>
                                                    }
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </Descriptions.Item>
                            </Descriptions>
                        </Collapse.Panel>
                    </Collapse>
                </Col>


            </Row >

            <Row style={{ marginBottom: 20 }}>
                <Col flex={1}>
                    <Table size="small" columns={columns} rowKey="PersonID" dataSource={curEmps} pagination={false} />
                </Col>
            </Row>
            <Row justify="center">
                <Col>
                    <Button onClick={() => setShowAddModal(true)}>Invitér medarbejder</Button>
                </Col>
            </Row>

            <EmployeeInviteModal visible={showAddModal} onClose={() => setShowAddModal(false)} />
            <EmployeePeriodModal visible={showPeriodModal} onClose={() => setShowPeriodModal(false)} />
            <EmployeeAllocModal visible={showAllocModal} onClose={() => setShowAllocModal(false)} />
        </Col >
    )
}