import { Spin } from "antd";

type Props = {
  children?: JSX.Element | JSX.Element[]
}
export default function Loader(props: Props) {
  return (
    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
      <Spin size="large" />
      <div style={{ marginTop: 10, fontSize: 12 }}>Indlæser...</div>
      {props.children}
    </div>
  )
}