// @ts-ignore
import splash from "../../../assets/splash.jpg";

type Props = {
  children?: JSX.Element | JSX.Element[]
}

export default function SplashLayout(props: Props) {
  return (
    <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundImage: `url(${splash})`, backgroundSize: "cover" }}>
      {props.children}
      <div style={{ position: "fixed", right: 10, bottom: 5, color: "white" }}>
        Foto af <strong><a style={{ color: "white" }} href="https://www.pexels.com/da-dk/@punttim?utm_content=attributionCopyText&amp;utm_medium=referral&amp;utm_source=pexels">Tim Gouw</a></strong>
      </div>
    </div>
  )
}