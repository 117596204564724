import { message } from "antd";
import { MemoExoticComponent, useEffect, useState } from "react";

import moment from "moment"

export function useWindowWidth() {
    const [width, setWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return width;
}


export async function rpc(method: string, params?: object): Promise<any> {
    const headers: HeadersInit = { "Content-Type": "application/json-rpc" }
    const jwt = localStorage.getItem("jwt")
    if (jwt) headers["Authorization"] = `Bearer ${jwt}`;

    try {
        return await fetch("/rpc", {
            headers,
            method: "POST",
            body: JSON.stringify({ "method": method, "params": params })
        })
            .then(r => r.json())
            .then(r => {
                if (r.error) throw new Error(r.error);
                return r.result
            })
    }
    catch (e: any) {
        console.error(e.message);
        message.error(e.message);
        throw e;
    }
}

export async function uploadFile(url: string, file: File) {
    await fetch(url, {
        method: "put",
        headers: { "Content-Type": file.type },
        body: file
    })
        .catch(e => {
            message.error(e.message)
            throw e;
        })
}

export function getActivePersonAlloc(month: Month, allocs?: Allocation[]): Allocation | null {
    let bestAlloc: Allocation | null = null;
    const m = monthToMoment(month)

    for (const alloc of allocs || []) {
        if (!alloc.Contract?.Signed) continue;
        const start = moment(alloc.Start);

        if (m.isBefore(start)) continue;

        if (bestAlloc) {
            const bestStart = moment(bestAlloc.Start);
            if (start.isBefore(bestStart))
                continue;
        }

        bestAlloc = alloc;
    }
    return bestAlloc;
}

export function getLatestRegistration(regs: Registration[]): Registration | null {
    if (!regs.length) return null;
    return [...regs].sort((a, b) => monthToMoment(b.Month).diff(monthToMoment(a.Month)))[0]
}

export function getLatestRegistrationLog(reg: Registration): RegistrationLog | null {
    if (!reg.Log?.length) return null;
    return [...reg.Log].sort((a, b) => moment(b.Time).diff(moment(a.Time)))[0]
}

export function monthToMoment(month: Month): moment.Moment {
    return moment(`${formatMonth(month)}-01`);
}

export function momentToMonth(m: moment.Moment): Month {
    return { Year: m.year(), Month: m.month() + 1 }
}

export function formatMonth(month: Month) {
    const yearStr = month.Year.toString();
    const monthStr = month.Month.toString().padStart(2, "0");
    return `${yearStr}-${monthStr}`
}

export const projectStart: Month = { Year: 2021, Month: 11 };
export const projectEnd: Month = { Year: 2023, Month: 6 };