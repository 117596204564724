import { useEffect, useState } from "react";
import { render } from "react-dom";
import { BrowserRouter, useNavigate, useLocation, Routes, Route, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import jwt_decode from "jwt-decode";

import "antd/dist/antd.css";
import "moment/locale/da";
import "./index.scss"

import { actions, store, useOrganisations, useRoles, useUser, useUserOrganisation } from "./utils/store";
import MenuLayout from "./components/layouts/MenuLayout";
import UserLayout from "./components/layouts/UserLayout";

import LoginModal from "./components/pages/login/components/LoginModal";
import UserDetailsModal from "./components/pages/login/components/UserDetailsModal";
import OrgDetailsModal from "./components/pages/login/components/OrgDetailsModal";
import RegistreringEmail from "./components/pages/registrering/RegistreringEmail";
import RegistreringEmailMonth from "./components/pages/registrering/RegistreringEmailMonth";
import OrganisationCVR from "./components/pages/organisation/OrganisationCVR";
import Loader from "./components/shared/Loader";
import OrgSignAftaleModal from "./components/pages/login/components/OrgSignAftaleModal";
import OrgSignDeMinimisModal from "./components/pages/login/components/OrgSignDeMinimisModal";
import { Button, ConfigProvider } from "antd";
import daDK from "antd/lib/locale/da_DK";
import Stamdata from "./components/pages/stamdata/Stamdata";
import Admin from "./components/pages/admin/Admin";
import ProjectAlias from "./components/pages/project/ProjectAlias";
import ControllingRegistrering from "./components/pages/controlling/ControllingRegistrering";
import Program from "./components/pages/program/Program";
import ControllingRegistreringEmailMonth from "./components/pages/controlling/ControllingRegistreringEmailMonth";

const JWT_EXP_BUFFER = 600; // 10 minutes

function App() {
    const user = useUser();
    const roles = useRoles();
    const userOrg = useUserOrganisation();
    const orgs = useOrganisations();

    const [loading, setLoading] = useState(true);

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const search = new URLSearchParams(location.search);
        let jwt = search.get("jwt");

        if (jwt) {
            search.delete("jwt");
            const newSearch = search.toString();
            navigate(location.pathname + newSearch ? newSearch : "")
        }
        else jwt = localStorage.getItem("jwt");

        if (jwt) {
            const payload = jwt_decode<any>(jwt);
            if (payload.exp > (new Date().getTime() / 1000) + JWT_EXP_BUFFER) {
                actions.user.finalizeLogin(jwt)
                    .then(() => setLoading(false))
            }
            else {
                localStorage.removeItem("jwt");
                setLoading(false);
            }
        }
        else setLoading(false);
    }, [])

    function onSignOut() {
        actions.user.logout();
        window.location.reload();
    }

    if (loading) return <Loader><Button onClick={onSignOut} size="small" style={{ marginTop: 20 }}>Log ud</Button></Loader>
    if (!user) return <LoginModal />
    if (!user.Complete) return <UserDetailsModal />

    if (userOrg) {
        if (!userOrg.Complete) return <OrgDetailsModal />
        if (userOrg.Aftale?.Signed !== true) return <OrgSignAftaleModal />
        const unsignedDeminimis = userOrg.Activities?.find(a => a.DeminimisSigned === false);
        if (unsignedDeminimis != null) return <OrgSignDeMinimisModal />
    }

    return (
        <UserLayout>
            <MenuLayout>
                <Routes>
                    {roles.includes("admin") && <Route path="/admin" element={<Admin />} />}
                    {
                        roles.includes("controller") &&
                        <>
                            <Route path="/controlling" element={<Navigate to="/controlling/registrering" />} />
                            <Route path="/controlling/registrering" element={<ControllingRegistrering />} />
                            <Route path="/controlling/registrering/:email" element={null} />
                            <Route path="/controlling/registrering/:email/:month" element={<ControllingRegistreringEmailMonth />} />
                        </>
                    }
                    {
                        roles.includes("program-lead") &&
                        <>
                            <Route path="/program" element={<Program />} />
                        </>
                    }
                    {
                        roles.includes("project-manager") &&
                        <>
                            <Route path="/projekt" element={<Navigate to={`/projekt/TODO`} />} />
                            <Route path="/projekt/:alias" element={<ProjectAlias />} />
                            <Route path="/stamdata" element={<Stamdata />} />
                        </>
                    }
                    {
                        orgs?.length &&
                        <>
                            {userOrg
                                ? <Route path="/organisation" element={<Navigate to={`/organisation/${userOrg.CVR}`} />} />
                                : <Route path="/organisation" element={<Navigate to={`/organisation/${orgs[0].CVR}`} />} />
                            }
                            <Route path="/organisation/:cvr" element={<OrganisationCVR />} />
                        </>
                    }

                    <Route path="/registrering" element={<Navigate to={`/registrering/${user.Email}`} />} />
                    <Route path="/registrering/:email" element={<RegistreringEmail />} />
                    <Route path="/registrering/:email/:month" element={<RegistreringEmailMonth />} />

                    <Route path="*" element={<Navigate to={`/registrering/${user.Email}`} />} />
                </Routes>
            </MenuLayout>
        </UserLayout>
    )
}

render(
    <ConfigProvider locale={daDK}>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </ConfigProvider>,
    document.getElementById("app"));


