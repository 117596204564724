import { Col, Row, Table, TableColumnsType, Tag } from "antd";
import { useEffect } from "react";
import { BiPen } from "react-icons/bi"
import { useNavigate, useParams } from "react-router";
import { actions, useCurPerson, useCurPersonOrganisation, useCurPersonRegistrations, usePersons, useUser } from "../../../utils/store";
import { formatMonth, momentToMonth, monthToMoment, projectEnd, projectStart } from "../../../utils/utils";
import PersonHeader from "../../shared/PersonHeader";

import moment from "moment"
import RegStatusTag from "../../shared/RegStatusTag";
import { Link } from "react-router-dom";

export default function RegistreringEmail() {
  const params = useParams()
  const navigate = useNavigate();

  const user = useUser();
  const persons = usePersons();
  const curPerson = useCurPerson();
  const curPersonRegs = useCurPersonRegistrations();
  const curPersonOrg = useCurPersonOrganisation();

  useEffect(() => {
    if (!persons || !user) return

    actions.selectMonth(null);

    const newPerson = persons.find(p => p.Email === params.email);
    if (newPerson && newPerson.Complete) actions.selectPerson(newPerson.PersonID);
    else if (params.email !== user.Email) navigate(`/registrering/${user.Email}`)

  }, [params, persons, user])

  function CustomRow(rowProps: any, extra: any) {
    if (!curPerson) return null;
    const regId = rowProps["data-row-key"];
    const reg = regs.find(r => r.RegistrationID === regId);
    if (!reg) return null;

    const month = monthToMoment(reg.Month)
    const isFuture = month.isAfter(moment());

    const row = <tr {...rowProps} style={{ cursor: isFuture ? "default" : "pointer", opacity: isFuture ? 0.3 : 1 }} />
    if (isFuture) return row

    return (
      <tr
        {...rowProps}
        style={{ cursor: isFuture ? "default" : "pointer" }}
        onClick={() => reg ? navigate(`/registrering/${curPerson.Email}/${formatMonth(reg.Month)}`) : null}
      />
    );
  }

  console.log("CUR", curPerson, curPersonRegs)

  if (!curPerson || !curPersonRegs) return null;
  const columns: TableColumnsType<Registration> = [
    {
      title: "Måned",
      key: "month",
      dataIndex: "Month",
      render: (v, o) => {
        const moment = monthToMoment(o.Month);
        const formatted = moment.format("MMMM YYYY");
        if (moment.isAfter()) return formatted;
        return <Link to={`/registrering/${curPerson.Email}/${formatMonth(o.Month)}`}>{formatted}</Link>
      }
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "Status",
      width: 100,
      align: "left",
      render: (v, o) => <RegStatusTag reg={o} />
    }
  ]


  const regs: Registration[] = [];
  let curMonth = monthToMoment(curPerson.Start || curPersonOrg?.Start || projectStart);
  const endMonth = monthToMoment(curPerson.End || curPersonOrg?.End || projectEnd);
  let idx = 0;


  while (curMonth.isBefore(endMonth)) {
    const existing = curPersonRegs.find(r => monthToMoment(r.Month).diff(curMonth, "d") === 0);
    if (existing != null) regs.push(existing);
    else {
      const reg: Registration = {
        RegistrationID: `${--idx}`,
        PersonID: curPerson.PersonID,
        Status: "missing",
        Month: momentToMonth(curMonth),
        Activities: [],
        Lonseddel: false,
        Log: [],
      };

      if (monthToMoment(reg.Month).isAfter(moment()))
        reg.Status = "waiting";
      regs.push(reg);
    }
    curMonth.add(1, "M");
  }

  console.log("HERE", regs)

  return (
    <Col flex="0 1 800px">
      <PersonHeader
        onPersonSelect={p => p ? navigate(`/registrering/${p.Email}`) : navigate("/registrering")}
        onOrgClick={o => navigate(`/organisation/${o.CVR}`)}
      />
      <Row style={{ marginBottom: 20 }}>
        <Col flex={1}>
          <Table size="small" columns={columns} dataSource={regs} rowKey="RegistrationID" pagination={false} components={{ body: { row: CustomRow } }} />
        </Col>
      </Row>
    </Col>
  )
}