import { Button, Checkbox, Form, Input, InputNumber, message, Modal, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { actions, useUser, useUserOrganisation } from "../../../../utils/store";
import SplashLayout from "../../../layouts/SplashLayout";
import UserLayout from "../../../layouts/UserLayout";
import Logo from "../../../shared/Logo";

export default function UserDetailsModal() {
  const user = useUser()
  const userOrg = useUserOrganisation();
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  if (!user) return null;

  async function onSubmit(values: any) {
    setLoading(true);
    actions.user.updateDetails(values)
      .finally(() => setLoading(false));
  }

  function onCprInput(e: any) {
    const textValue = e.target.value;
    const numberValue = textValue.replace(/\D/g, "");

    let newCpr = numberValue;
    if (newCpr.length > 6)
      newCpr = newCpr.slice(0, 6) + "-" + newCpr.slice(6, 10)
    else if (newCpr.length === 6 && textValue.endsWith("-"))
      newCpr += "-";

    e.target.value = newCpr
    setTimeout(() => { form.setFieldsValue({ "CPR": newCpr }) }, 0)
  }

  function onMobileInput(e: any) {
    const textValue = e.target.value;
    const numberValue = parseInt(textValue.replace(/\D/g, ""));
    const newMobile = Number.isNaN(numberValue) ? "" : numberValue.toString().slice(0, 8)

    e.target.value = newMobile
    setTimeout(() => { form.setFieldsValue({ "Mobile": newMobile }) }, 0)
  }

  return (
    <UserLayout>
      <SplashLayout>
        <Modal visible closable={false} footer={null}>
          <Row justify="center"><Logo /></Row>
          <p>Vi har behov for flg. informationer om dig</p>

          <Form form={form} labelCol={{ span: 5 }} onFinish={onSubmit}>
            <Form.Item name="FirstName" label="Fornavn" initialValue={user.FirstName} rules={[{ required: true, message: "Påkrævet" }]}><Input disabled={loading} /></Form.Item>
            <Form.Item name="LastName" label="Efternavn" initialValue={user.LastName} rules={[{ required: true, message: "Påkrævet" }]}><Input disabled={loading} /></Form.Item>
            <Form.Item name="CPR" label="CPR-nr." rules={[{ required: true, message: "Påkrævet" }, { min: 11, max: 11, message: "Skal være gyldigt" }]}><Input onInputCapture={onCprInput} disabled={loading} autoComplete="off" /></Form.Item>
            <Form.Item name="Mobile" label="Mobil-nr." initialValue={user.Mobile} rules={[{ required: true, message: "Påkrævet" }, { min: 8, max: 8, message: "Skal være gyldigt" }]}><Input onInput={onMobileInput} addonBefore="+45" disabled={loading} /></Form.Item>

            {userOrg?.Afregning === "faktisk-lon" &&
              <Form.Item name="Ugenorm" label="Ugenorm" tooltip="Antal arbejdstimer per uge (fx. 37)" rules={[{ required: true, message: "Påkrævet" }]}>
                <InputNumber min={0} step={1} />
              </Form.Item>
            }

            <Form.Item name="Consent" label="GDPR" required rules={[{ validator: async (r, v) => { if (!v) throw new Error("Du skal acceptere") } }]} valuePropName="checked">
              <Checkbox>Jeg accepterer <a href="https://cleancluster.dk/persondata/" target="_blank">behandlingen af personoplysninger</a></Checkbox>
            </Form.Item>

            <Row justify="center"><Button loading={loading} htmlType="submit" type="primary">Fortsæt</Button></Row>
          </Form>
        </Modal>
      </SplashLayout>
    </UserLayout>
  )
}