import { Col, Divider, Dropdown, Input, Menu, Row } from "antd";
import { useState } from "react";
import { BiCaretDown, BiSearch } from "react-icons/bi";
import { useCurMonth, useCurPerson, useCurPersonOrganisation, useCurPersonRegistrations, useOrganisations, usePersons } from "../../utils/store";
import { formatMonth, momentToMonth, monthToMoment, projectEnd, projectStart } from "../../utils/utils";

import moment from "moment";
import RegStatusTag from "./RegStatusTag";

type Props = {
  onMonthSelect?: (month: Month | null) => any
  onPersonSelect?: (person: Person | null) => any
  onOrgClick?: (org: Organisation) => any

  showMonth?: boolean
  showMissingMonths?: boolean
}

export default function PersonHeader(props: Props) {
  const persons = usePersons();
  const orgs = useOrganisations();
  const curPerson = useCurPerson();
  const curPersonOrg = useCurPersonOrganisation();
  const curPersonRegs = useCurPersonRegistrations();
  const curMonth = useCurMonth();

  const [search, setSearch] = useState("");

  if (!curPerson) return null;

  function onPersonSelect(i: any) {
    if (!persons) return

    const newPerson = persons.find(p => p.PersonID === i.key) || null;
    props.onPersonSelect?.(newPerson);
  }
  function onMonthSelect(i: any) {
    const [yearStr, monthStr] = i.key.split("-");
    const newMonth: Month = { Year: parseInt(yearStr), Month: parseInt(monthStr) }
    props.onMonthSelect?.(newMonth)
  }

  function Name({ caret }: { caret?: boolean }) {
    if (!curPerson) return null;

    return (
      <Col>
        <Row align="middle" style={{ fontSize: 16, fontWeight: 700, lineHeight: 1 }}>
          {curPerson.Complete ? curPerson.FullName : "<mangler>"}
          {caret && <BiCaretDown style={{ marginTop: 2 }} fontSize={16} />}
        </Row>
        {curPerson && <Row style={{ fontSize: 12, lineHeight: 1.3 }}>{curPerson.Email}</Row>}
        {curPersonOrg &&
          <Row style={{ fontSize: 12, lineHeight: 1.3 }}>
            {
              props.onOrgClick
                ? <a onClickCapture={e => { props.onOrgClick?.(curPersonOrg); e.stopPropagation(); }}>{curPersonOrg.Name}</a>
                : curPersonOrg.Name
            }
          </Row>
        }
      </Col>
    )
  }

  if (!persons || !orgs) return null;

  let nameEl: JSX.Element | null = null;
  if (persons.length <= 1) nameEl = <Name />
  if (persons.length > 1) {
    nameEl = (
      <>
        <Dropdown
          trigger={["click"]}
          destroyPopupOnHide
          overlay={
            <Menu onClick={onPersonSelect} selectedKeys={curPerson ? [curPerson.PersonID] : []}>
              <Menu.Item key="search">
                <Input autoFocus onClick={e => e.stopPropagation()} value={search} onChange={e => setSearch(e.target.value)} type="search" suffix={<BiSearch />} allowClear />
              </Menu.Item>
              {persons
                .filter(p => {
                  if (search.length === 0) return true;
                  const org = p.OrganisationID ? orgs.find(o => o.OrganisationID === p.OrganisationID) : null

                  return `${p.FullName || ""} ${p.Email || ""} ${org?.Name || ""}`.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
                .map(p =>
                  <Menu.Item key={p.PersonID} disabled={!p.Complete}>
                    <Row style={{ lineHeight: 1.1, fontWeight: 600 }}>{!p.Complete ? "<inviteret>" : p.FullName}</Row>
                    <Row style={{ lineHeight: 1.1, fontSize: 12 }}>{p.Email}</Row>
                    <Row style={{ lineHeight: 1.1, fontSize: 12 }}>{p.OrganisationID ? (orgs.find(o => o.OrganisationID === p.OrganisationID)?.Name || "<mangler>") : null}</Row>
                  </Menu.Item>
                )}
            </Menu>
          }
        >
          <Row align="top" style={{ display: "inline-flex", cursor: "pointer" }}><Name caret /></Row>
        </Dropdown>
      </>
    );
  }

  if (!curPersonRegs) return null;

  const regs: Registration[] = [];
  if (props.showMonth) {
    let curMonth = monthToMoment(curPerson.Start || curPersonOrg?.Start || projectStart);
    let endMonth = monthToMoment(curPerson.End || curPersonOrg?.End || projectEnd)
    let idx = 0;

    while (curMonth.isBefore(endMonth)) {
      const existing = curPersonRegs.find(r => monthToMoment(r.Month).diff(curMonth, "M") === 0);
      if (existing != null) {
        if (existing.Status !== "missing" || props.showMissingMonths)
          regs.push(existing);
      }
      else if (props.showMissingMonths) {
        const reg: Registration = {
          RegistrationID: `${--idx}`,
          PersonID: curPerson.PersonID,
          Status: "missing",
          Month: momentToMonth(curMonth),
          Activities: [],
          Log: [],
          LonseddelFileID: ""
        };
        if (!monthToMoment(reg.Month).isAfter(moment())) regs.push(reg);
      }

      curMonth.add(1, "M");
    }
  }

  return (
    <Col flex={1}>
      <Row align="middle" justify="space-between">
        <Col>
          {nameEl}
        </Col>
        <Col>
          {props.showMonth && curMonth &&
            <Dropdown trigger={["click"]} destroyPopupOnHide placement="bottomRight" overlay={
              <Menu onClick={onMonthSelect} selectedKeys={[`${formatMonth(curMonth)}`]} >
                {
                  regs.map(r =>
                    <Menu.Item key={formatMonth(r.Month)} >
                      <Row gutter={20}>
                        <Col flex={1}>{monthToMoment(r.Month).format("MMMM YYYY")}</Col>
                        <Col><RegStatusTag reg={r} /></Col>
                      </Row>
                    </Menu.Item>
                  )
                }
              </Menu>
            }>
              <Row align="middle" style={{ cursor: "pointer", fontWeight: 600 }}>{monthToMoment(curMonth).format("MMMM YYYY")} <BiCaretDown fontSize={16} /></Row>
            </Dropdown>
          }
        </Col>
      </Row>
      <Divider style={{ margin: "24px 0 0 0" }} />
    </Col>
  )
}