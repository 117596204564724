import { Button, message } from "antd";
import { useState } from "react";
import { actions } from "../../utils/store";

type Props = {
  doc: Signature | null
}
export default function FakeSignButton(props: Props) {
  const [loading, setLoading] = useState(false)

  if (!props.doc) return null;

  async function onClick() {
    if (!props.doc) return

    setLoading(true);
    try {
      await actions.test.fakeSign(props.doc.CaseFile)
      message.success("Dokumentet er blevet fake-underskrevet")
    }
    finally {
      setLoading(false);
    }
  }

  return <Button loading={loading} style={{ background: "yellow", color: "red" }} onClick={onClick}>FAKE UNDERSKRIV</Button>
}