import { Alert, Button, Col, Divider, Form, Input, InputNumber, message, Row, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { actions, useCurMonth, useCurPerson, useCurPersonOrganisation, useCurPersonRegistrations, usePersons, useProjects, useUser } from "../../../utils/store";
import { formatMonth, getActivePersonAlloc } from "../../../utils/utils";
import PDFViewer from "../../shared/PDFViewer";
import PersonHeader from "../../shared/PersonHeader";
import RegLog from "../../shared/RegLog";
import StatusAlert from "../../shared/StatusAlert";

export default function ControllingRegistreringEmailMonth() {
  const projs = useProjects();
  const persons = usePersons();
  const curPerson = useCurPerson();
  const curPersonOrg = useCurPersonOrganisation();
  const curMonth = useCurMonth();
  const curPersonRegs = useCurPersonRegistrations();

  const [reg, setReg] = useState<Registration | null>(null);

  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (!persons) return

    const [yearStr, monthStr] = (params.month as string).split("-");
    const month: Month = { Year: parseInt(yearStr), Month: parseInt(monthStr) };

    actions.selectMonth(month);

    const newPerson = persons.find(p => p.Email === params.email);
    if (!newPerson) {
      navigate(`/controlling/registrering`);
      return
    }

    actions.selectPerson(newPerson.PersonID);

  }, [params.email, params.month, persons]);

  useEffect(() => {
    if (!curMonth || !curPerson || !curPersonRegs) return;

    let reg = curPersonRegs.find(r => r.Month.Year === curMonth.Year && r.Month.Month === curMonth.Month) || null;
    if (!reg || reg.Status === "missing") {
      navigate(`/controlling/registrering`)
      return
    }

    setReg(reg);

  }, [curMonth, curPersonRegs]);

  if (!curPerson || !curMonth || !reg) return null;

  const alloc = getActivePersonAlloc(curMonth, curPerson.Allocations || []);

  async function onGodkend(values: any) {
    if (!curPerson || !curMonth) return;

    await actions.registrations.accept({
      PersonID: curPerson.PersonID,
      Month: curMonth,
      AM: values.AM,
      ATP: values.ATP,

    });
  }

  async function onAfvisClick() {
    if (!curPerson || !curMonth) return;

    const reason = window.prompt("Angiv årsag:");
    if (!reason) return;

    await actions.registrations.reject({
      PersonID: curPerson.PersonID,
      Month: curMonth,
      Reason: reason
    });
  }


  const afregning: Afregning = curPersonOrg?.Afregning || "standardsats"
  const acts = curPersonOrg?.Activities || curPerson.Activities || [];

  return (
    <Col flex={1}>
      <Row gutter={0}>
        <Col flex="0 0 30%">
          <Row>
            <PersonHeader
              onOrgClick={o => navigate(`/controlling/registrering?org=${o.CVR}`)}
              onPersonSelect={p => p ? navigate(`/controlling/timer/${p.Email}/${formatMonth(curMonth)}`) : navigate("/controlling/registrering")}
              onMonthSelect={m => m ? navigate(`/controlling/timer/${curPerson.Email}/${formatMonth(m)}`) : null}
              showMonth
            />
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col flex={1}>
              <Row justify="center" style={{ marginBottom: 20 }}>
                <Link to={`/registrering/${params.email}/${params.month}`}><Button>Gå til registrering</Button></Link>
              </Row>
              <Row justify="center">
                <Col flex="0 0 70%"><StatusAlert reg={reg} /></Col>
              </Row>
              <Form onFinish={onGodkend} labelCol={{ span: 8 }}>
                {
                  curPersonOrg?.Afregning == "faktisk-lon" &&
                  <>
                    <Form.Item name="AM" label="AM-indkomst" rules={[{ required: true, message: "Påkrævet" }]}>
                      <InputNumber addonAfter="Kr." />
                    </Form.Item>
                    <Form.Item name="ATP" label="ATP bidrag" rules={[{ required: true, message: "Påkrævet" }]}>
                      <InputNumber addonAfter="Kr." />
                    </Form.Item>
                    <Form.Item name="Pension" label="Pensionsbidrag" rules={[{ required: true, message: "Påkrævet" }]}>
                      <InputNumber addonAfter="Kr." />
                    </Form.Item>
                    <Form.Item name="Ferie" label="Feriepenge" rules={[{ required: true, message: "Påkrævet" }]}>
                      <InputNumber addonAfter="Kr." />
                    </Form.Item>
                  </>
                }
                <Row gutter={10} justify="center">
                  <Col flex="1 1 100px"><Button style={{ width: "100%" }} type="primary" htmlType="submit" >Godkend</Button></Col>
                  <Col flex="1 1 100px"><Button style={{ width: "100%" }} type="primary" danger onClick={onAfvisClick}>Afvis</Button></Col>
                </Row>
              </Form>
            </Col>

          </Row>
        </Col>
        <Divider type="vertical" style={{ margin: "0 30px", height: "calc(100vh - 40px)" }} />
        <Col flex="1 1 auto">
          <Tabs style={{ marginTop: 30 }} >
            <Tabs.TabPane key="timeseddel" tab="Timeseddel">
              <Tabs>
                {acts.map(pa => {

                  const ra = reg.Activities?.find(ra => ra.ActivityID == pa.ActivityID);

                  if (!ra) return null;
                  if (!ra.Timeseddel) return null;

                  const projAlias = pa.ActivityID.split(".")[0]
                  const p = projs?.find(p => p.Alias === projAlias);
                  if (!p) return null;
                  const a = p.Activities?.find(pa => pa.ActivityID === pa.ActivityID);
                  if (!a) return null;

                  return (
                    <Tabs.TabPane key={a.ActivityID} tab={
                      <>
                        <Row style={{ fontWeight: 600, fontSize: 14, lineHeight: 1.2 }}>{p.Name}</Row>
                        {a.Name && <Row style={{ fontSize: 12, lineHeight: 1.2 }}>{a.Name}</Row>}
                      </>
                    }>
                      <PDFViewer
                        getUrl={() => actions.registrations.getTimeseddelDownloadLink(curPerson.PersonID, curMonth, pa.ActivityID)}
                        style={{ height: "calc(100vh - 138px)" }}
                      />
                    </Tabs.TabPane>
                  )
                })}
              </Tabs>
            </Tabs.TabPane>
            {
              afregning == "faktisk-lon" &&
              <Tabs.TabPane key="lønseddel" tab="Lønseddel">
                <PDFViewer
                  getUrl={() => actions.registrations.getLonseddelDownloadLink(curPerson.PersonID, curMonth)}
                  style={{ height: "calc(100vh - 138px)" }}
                />
              </Tabs.TabPane>

            }
            {
              // alloc &&
              // <Tabs.TabPane key="allokering" tab="Allokering">
              //   <iframe src={`${API_URL}/fyrtaarn/files/${alloc.contract?.signedFileId}?jwt=${jwt}#zoom=100`} width="100%" style={{ height: "calc(100vh - 138px)" }} />
              //   <Alert type="success" style={{ position: "absolute", bottom: 20, left: 20, fontSize: 14, fontWeight: 600 }} message="Underskrevet" />
              // </Tabs.TabPane>
            }
            <Tabs.TabPane key="history" tab="Historik">
              <Row justify="center">
                <Col flex="0 1 400px">
                  <RegLog reg={reg} />
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row >
    </Col >
  )
}
