import { Alert, Button, Checkbox, Col, DatePicker, Divider, Form, Input, InputNumber, message, Modal, Row, Spin, Switch } from "antd";
import { useEffect, useState } from "react";
import { BiInfoCircle, BiPen, BiTrash } from "react-icons/bi";

import daDK from 'antd/lib/locale/da_DK';
import moment from "moment";
import { useForm } from "antd/lib/form/Form";
import { actions, useUserOrganisation } from "../../../../utils/store";
import Logo from "../../../shared/Logo";
import UserLayout from "../../../layouts/UserLayout";
import SplashLayout from "../../../layouts/SplashLayout";

export default function OrgDetailsModal() {
  const userOrg = useUserOrganisation();
  const [form1] = useForm();

  const [godkender, setGodkender] = useState<Signer>({ Name: "", Email: "" });
  const [tegningsberettiget, setTegningsberettiget] = useState<Signer>({ Name: "", Email: "" });
  const [isSMV, setIsSMV] = useState(false);
  const [hasFunding, setHasFunding] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [deminimis, setDeminimis] = useState<DeminimisLine[]>([]);
  const [loading, setLoading] = useState(false);

  if (userOrg == null) return null;

  function onValuesChangePt1(c: any, v: any) {
    setIsSMV(v.SMV || false);
    setHasFunding(v.hasFunding || false);
    setGodkender(v.Godkender);
    setTegningsberettiget(v.Tegningsberettiget);
  }

  function onValuesChangePt2(c: any, v: any) {
    setIsConfirmed(v.isConfirmed)
    setDeminimis(v.Deminimis || [])
  }

  async function onSubmit() {
    if (!userOrg) return

    await form1.validateFields();

    setLoading(true)
    try {
      await actions.organisations.updateDetails({
        OrganisationID: userOrg.OrganisationID,
        Tegningsberettiget: tegningsberettiget,
        Godkender: godkender,
        SMV: isSMV,
        Deminimis: hasFunding ? deminimis : [],
      });
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <UserLayout>
      <SplashLayout>
        <Modal visible closable={false} footer={null}>
          <Row justify="center"><Logo /></Row>
          <Row justify="center">Vi har behov for flg. informationer om din organisation</Row>
          <Row justify="center" style={{ fontWeight: 600, fontSize: 18 }}>{userOrg.Name}</Row>
          <Row justify="center" style={{ fontWeight: 600, fontSize: 12 }}>CVR: {userOrg.CVR}</Row>

          <Row justify="center" style={{ marginTop: 20 }}>
            <Col flex="0 1 400px">
              <Form form={form1} onValuesChange={onValuesChangePt1} colon={false}>
                <Divider>Tegningsberettiget</Divider>
                <Form.Item colon labelCol={{ span: 6 }} name={["Tegningsberettiget", "Name"]} label="Fulde navn" style={{ marginBottom: 5 }} rules={[{ required: true, message: "Påkrævet" }]}>
                  <Input disabled={loading} />
                </Form.Item>
                <Form.Item colon labelCol={{ span: 6 }} name={["Tegningsberettiget", "Email"]} label="Email" style={{ marginBottom: 10 }} rules={[{ required: true, message: "Påkrævet" }]}>
                  <Input type="email" disabled={loading} />
                </Form.Item>
                <Divider>Godkender</Divider>
                <Alert style={{ marginBottom: 20 }} type="info" showIcon icon={<BiInfoCircle />} description="Her skal du angive den person som skal stå for at andengodkende timeregistreringer" />
                <Form.Item colon labelCol={{ span: 6 }} name={["Godkender", "Name"]} label="Fulde navn" style={{ marginBottom: 5 }} rules={[{ required: true, message: "Påkrævet" }]}>
                  <Input disabled={loading} />
                </Form.Item>
                <Form.Item colon labelCol={{ span: 6 }} name={["Godkender", "Email"]} label="Email" style={{ marginBottom: 10 }} rules={[{ required: true, message: "Påkrævet" }]}>
                  <Input type="email" disabled={loading} />
                </Form.Item>
                <Divider>Støtte</Divider>

                <Form.Item name="SMV" label="Er I en SMV?" tooltip="En SMV er en virksomhed med færrre end 250 ansatte" style={{ textAlign: "right", marginBottom: 5 }} valuePropName="checked">
                  <Switch disabled={loading} checkedChildren="Ja" unCheckedChildren="Nej" />
                </Form.Item>
                {
                  isSMV &&
                  <Form.Item name="hasFunding" labelAlign="left" label={<>Har I modtaget statsstøtte inden for en periode<br />på tre regnskabsår?</>} style={{ textAlign: "right", marginBottom: 5, height: 45 }} valuePropName="checked">
                    <Switch disabled={loading} checkedChildren="Ja" unCheckedChildren="Nej" />
                  </Form.Item>
                }
              </Form>
            </Col>
          </Row>
          <Row justify="center">
            <Col flex="0 1 400px">
              <Form layout="vertical" onFinish={onSubmit} onValuesChange={onValuesChangePt2} initialValues={{ Deminimis: [{}] }}>
                {
                  hasFunding &&
                  <>
                    <Form.List name="Deminimis" >
                      {
                        (fields, { add, remove }) =>
                          <div style={{ padding: "20px" }}>
                            <strong>Tilføj en linje for hver gang I har modtaget støtte inden for en periode på tre regnskabsår</strong>
                            <Divider style={{ margin: "10px 0" }} />
                            {
                              fields.map(({ key, name, ...restFields }) =>
                                <div key={key}>
                                  <Form.Item {...restFields} name={[name, "Date"]} label="Dato" style={{ marginBottom: 5 }} rules={[{ required: true, message: "Påkrævet" }]}><DatePicker disabledDate={d => d.isAfter(moment())} showToday={false} format="DD-MM-YYYY" locale={daDK.DatePicker} disabled={loading} size="small" style={{ width: "100%" }} /></Form.Item>
                                  <Form.Item {...restFields} name={[name, "Amount"]} label="Beløb" style={{ marginBottom: 5 }} rules={[{ required: true, message: "Påkrævet" }]}><InputNumber decimalSeparator="," addonAfter="kr." min={0} precision={2} disabled={loading} size="small" style={{ width: "100%" }} /></Form.Item>
                                  <Form.Item {...restFields} name={[name, "Ordning"]} label="Ordning" style={{ marginBottom: 5 }} rules={[{ required: true, message: "Påkrævet" }]} tooltip=" "><Input disabled={loading} size="small" /></Form.Item>
                                  <Form.Item {...restFields} name={[name, "Admin"]} label="Bevillingsgiver" style={{ marginBottom: 5 }} rules={[{ required: true, message: "Påkrævet" }]} tooltip=" "><Input disabled={loading} size="small" /></Form.Item>
                                  {fields.length > 1 &&
                                    <Row justify="center">
                                      <Button disabled={loading} danger type="link" onClick={() => remove(name)}>
                                        <Row align="middle"><BiTrash /> Slet linje</Row>
                                      </Button>
                                    </Row>
                                  }
                                  <Divider style={{ margin: "10px 0" }} />
                                </div>
                              )
                            }
                            <Row justify="center"><Button disabled={loading} type="primary" size="small" onClick={() => add()}>Tilføj linje</Button></Row>
                          </div>
                      }
                    </Form.List>
                  </>
                }
                <Divider style={{ margin: "40px 0 10px 0" }} />
                <Row justify="center">
                  <Form.Item name="isConfirmed" rules={[{
                    validator: async (r, v) => { if (v !== true) throw new Error("error"); },
                    message: "Påkrævet"
                  }]} valuePropName="checked">
                    <Checkbox disabled={loading} >Jeg bekræfter at ovenstående informationer er korrekte</Checkbox>
                  </Form.Item>
                </Row>

                <Row justify="center">
                  <Button loading={loading} htmlType="submit" type="primary" disabled={!isConfirmed}>Fortsæt</Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </Modal >
      </SplashLayout>
    </UserLayout>
  )
}