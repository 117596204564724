import { Col, Row, Tooltip } from "antd";
import moment from "moment";
import { BiCommentDetail } from "react-icons/bi";
import { usePersons } from "../../utils/store";

type Props = {
  reg: Registration
}

export default function RegLog(props: Props) {
  const persons = usePersons();

  return (
    <>
      {
        [...(props.reg.Log || [])].sort((a, b) => moment(a.Time).diff(moment(b.Time)))
          .map((a, i) =>
            <Row key={i} style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)", padding: "5px 0" }}>
              <Col flex={1}>
                <Row justify="space-between" >
                  <Col flex="0 0 33%">
                    <Tooltip title={moment(a.Time).format("LLLL")}>
                      {moment(a.Time).format("L")}
                    </Tooltip>
                  </Col>
                  <Col flex="0 0 33%">
                    <Row justify="center">
                      {persons?.find(p => p.PersonID == a.PersonID)?.FullName || null}
                    </Row>
                  </Col>
                  <Col flex="0 0 33%">
                    <Row justify="end" style={{ fontWeight: 600 }}>{a.Action}</Row>
                  </Col>
                </Row>
                <Row align="middle">{a.Note ? <><BiCommentDetail style={{ marginRight: 5 }} /> {a.Note}</> : null}</Row>
              </Col>
            </Row>
          )
      }
    </>
  )
}