import { Button, Col, DatePicker, Divider, Form, Input, InputNumber, Row } from "antd"
import { BiTrash } from "react-icons/bi";
import { useCurMonth } from "../../../../utils/store";
import { monthToMoment, useWindowWidth } from "../../../../utils/utils";

type Props = {
  name: any
  formItemFields: any
  locked: boolean
  onRemove: (name: any) => any
}

export default function TimeRegRow(props: Props) {
  const width = useWindowWidth();
  const curMonth = useCurMonth();

  if (!curMonth) return null;

  return (
    <Row justify="center" align="top" gutter={10} >
      <Col flex={1}>
        <Row gutter={10}>
          <Col flex="1 0 150px">
            <Form.Item name={[props.name, "Date"]} {...props.formItemFields} style={{ margin: "5px 0" }} rules={[{ required: true, message: "Påkrævet" }]}>
              <DatePicker
                showToday={false} defaultPickerValue={monthToMoment(curMonth)}
                disabled={props.locked} placeholder="Dato" disabledDate={m => m.diff(monthToMoment(curMonth), "M") != 0}
                format="DD-MM-YYYY" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col flex="1 0 150px">
            <Form.Item name={[props.name, "Hours"]} {...props.formItemFields} style={{ margin: "5px 0" }} rules={[
              { required: true, message: "Påkrævet" },
              { message: <div style={{ maxWidth: 88 }}>Mindste enhed er et kvarter<br />(0.25 timer)</div>, validator: async (r, v) => { if (v && v != Math.floor(v * 4) / 4) throw "Error" } },
            ]}>
              <InputNumber decimalSeparator="," disabled={props.locked} placeholder="Timer" step={0.25} min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col flex="100 0 auto">
            <Form.Item name={[props.name, "Description"]} {...props.formItemFields} style={{ margin: "5px 0" }} rules={[{ required: true, message: "Påkrævet" }]}>
              <Input disabled={props.locked} placeholder="Arbejdsbeskrivelse" />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col flex="0 0 auto" style={{ padding: 5 }}>
        <Row align="middle">
          <Button disabled={props.locked} style={{ padding: 0 }} type="link" danger onClick={() => props.onRemove(props.name)} >
            <Row align="middle">
              <BiTrash size={18} />
            </Row>
          </Button>
        </Row>
      </Col>
      {width < 550 && <Divider style={{ margin: "10px 0" }} />}
    </Row>
  )
}