import { Button, Col, Form, Input, Row } from "antd"
import { BiListPlus } from "react-icons/bi"
import { useProjects } from "../../../../utils/store";
import { useWindowWidth } from "../../../../utils/utils";
import TimeRegRow from "./TimeRegRow";

type Props = {
  idx: number
  regAct: RegistrationActivity
  locked: boolean
  showHeader: boolean
}

export default function TimeRegAct(props: Props) {
  const width = useWindowWidth();
  const projs = useProjects();

  if (!projs) return null

  let proj: Project | null = null;
  let act: Activity | null = null;

  for (const p of projs) {
    for (const a of (p.Activities || [])) {
      if (a.ActivityID === props.regAct.ActivityID) {
        proj = p;
        act = a;
      }
    }
    if (proj != null) break;
  }

  if (!proj || !act) return null

  const totalHours = (props.regAct.Lines || []).reduce((p, c) => p + (c.Hours || 0), 0)

  return (
    <Row style={{ marginBottom: 50 }}>
      <Col flex={1}>
        {props.showHeader && <Row style={{ fontWeight: 600, fontSize: 16, lineHeight: 1.2 }}>{proj.Name}</Row>}
        {props.showHeader && act.Name && <Row style={{ fontSize: 12, lineHeight: 1.2 }}>{act.Name}</Row>}

        <Row style={{ marginTop: 10 }}>
          <Col flex={1}>
            <Form.Item hidden name={["Activities", props.idx, "ActivityID"]} >
              <Input />
            </Form.Item>
            <Form.List name={["Activities", props.idx, "Lines"]}>
              {(fields, { add, remove }) => {
                return (
                  <>
                    {
                      fields.map(({ key, name, ...restFields }) =>
                        <TimeRegRow key={key} name={name} locked={props.locked} formItemFields={restFields} onRemove={n => remove(n)} />)
                    }
                    <Row align="middle" justify="space-between" gutter={10} style={{ marginTop: 5 }}>
                      {
                        width < 600
                          ? (
                            <>
                              <Col flex="1 0 100%" style={{ textAlign: "center", marginBottom: 10, fontStyle: "italic" }}>
                                {fields.length == 0 && "Der er ingen linjer i timeregistreringen"}
                              </Col>

                              <Col flex="1 0 150px">
                                <Button disabled={props.locked} onClick={() => add({})} icon={<BiListPlus size={18} style={{ marginBottom: -3, marginRight: 5 }} />}>Tilføj linje</Button>
                              </Col>

                              <Col flex="1 0 150px">
                                <Row justify="end" gutter={5}>
                                  <Col><strong>Timer i alt: </strong></Col>
                                  <Col>{totalHours.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                </Row>
                              </Col>

                            </>
                          )
                          : (
                            <>
                              <Col flex="0 0 150px">
                                <Button disabled={props.locked} onClick={() => add({})} icon={<BiListPlus size={18} style={{ marginBottom: -3, marginRight: 5 }} />}>Tilføj linje</Button>
                              </Col>

                              <Col flex="1 0 auto" style={{ textAlign: "center", fontStyle: "italic" }}>
                                {fields.length == 0 && "Der er ingen linjer i timeregistreringen"}
                              </Col>
                              <Col flex="0 0 118px" style={{ textAlign: "right" }}><strong>Timer i alt: </strong>{totalHours.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                              <Col flex="0 0 32px"></Col>
                            </>
                          )
                      }
                    </Row>
                  </>
                )
              }}
            </Form.List>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}