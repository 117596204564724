import { useEffect, useState } from "react";
import Loader from "./Loader";

type Props = {
  style?: React.CSSProperties
  getUrl: () => Promise<string>
}

export default function PDFViewer(props: Props) {
  const [url, setUrl] = useState("")

  useEffect(() => {
    props.getUrl().then(setUrl)
  }, [])

  if (!url) return <Loader />
  return <iframe src={`${url}#zoom=100`} width="100%" style={props.style} />
}