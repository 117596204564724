import { useState, useEffect } from "react";

import { Button, Col, Form, message, Row, Drawer } from "antd";
import { BiArrowBack, BiCommentDetail, BiHistory, BiLock } from "react-icons/bi";

import PersonHeader from "../../../components/shared/PersonHeader";

import moment from "moment";
import { actions, useCurMonth, useCurPerson, useCurPersonOrganisation, useOrganisations, usePersons, useRegistrations, useRoles } from "../../../utils/store";
import { formatMonth, getActivePersonAlloc, monthToMoment, projectEnd, projectStart, useWindowWidth } from "../../../utils/utils";
import { useParams, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import StatusAlert from "../../shared/StatusAlert";
import Loader from "../../shared/Loader";
import RegContactModal from "./components/RegContractModal";
import RegSubmitModal, { RegActValue, RegValue } from "./components/RegSubmitModal";
import TimeRegAct from "./components/TimeRegAct";
import RegLog from "../../shared/RegLog";


export default function RegistreringEmailMonth() {
    const roles = useRoles();
    const params = useParams();
    const navigate = useNavigate();

    const curPerson = useCurPerson();
    const curPersonOrg = useCurPersonOrganisation();
    const curMonth = useCurMonth();

    const orgs = useOrganisations();
    const persons = usePersons();
    const regs = useRegistrations();

    const width = useWindowWidth();
    const [reg, setReg] = useState<Registration | null>(null);

    const [timeRegform] = Form.useForm();

    const [showContact, setShowContact] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false);

    const isLocked = !(reg?.Status == "missing" || reg?.Status == "rejected");

    const [values, setValues] = useState<RegValue>({ Activities: [] });

    const [debounce, setDebounce] = useState<any>(null);
    const [showLog, setShowLog] = useState(false);

    const [hasChanged, setHasChanged] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        console.log("RUNNING 1")
        if (!persons || !orgs || !regs) return;

        const [yearStr, monthStr] = (params.month as string).split("-");
        const month = { Year: parseInt(yearStr), Month: parseInt(monthStr) }
        const monthMoment = monthToMoment(month);

        const newPerson = persons.find(p => p.Email === params.email);
        if (!newPerson) {
            navigate("/registrering");
            return;
        }
        const newPersonOrg = newPerson.OrganisationID ? orgs.find(o => o.OrganisationID === newPerson.OrganisationID) : null;

        const start = monthToMoment(newPerson?.Start || newPersonOrg?.Start || projectStart)
        const end = monthToMoment(newPerson?.End || newPersonOrg?.End || projectEnd)

        if (start.isAfter(monthMoment) || end.isBefore(monthMoment)) {
            navigate(`/registrering/${newPerson.Email}`);
            return;
        }

        actions.selectMonth(month);

        if (newPerson && newPerson.Complete) actions.selectPerson(newPerson.PersonID);
        else navigate(`/registrering`)

    }, [params.email, params.month, orgs, persons, regs]);

    useEffect(() => {
        if (hasChanged) {
            setHasChanged(false);
            actions.registrations.get();
        }
    }, [curMonth, curPerson])

    useEffect(() => {
        return () => { actions.registrations.get() }
    }, [])

    useEffect(() => {
        if (!regs || !curPerson || !curMonth) return;

        let reg = regs.find(r => r.PersonID === curPerson.PersonID && monthToMoment(r.Month).diff(monthToMoment(curMonth), "d") == 0) || null;
        const activities = (curPersonOrg ? curPersonOrg.Activities : curPerson.Activities) || [];

        if (!reg) {
            reg = {
                RegistrationID: "-1",
                PersonID: curPerson.PersonID,
                Month: { ...curMonth },
                Status: "missing",
                Activities: [],
                Lonseddel: false,
                Log: [],
            };
        }
        if (monthToMoment(reg.Month).isAfter(moment())) {
            navigate(`/registrering/${curPerson.Email}`);
            return;
        }

        if (!reg.Activities)
            reg.Activities = [];

        for (const a of activities) {
            if (reg.Activities.find(a => a.ActivityID == a.ActivityID)) continue;

            reg.Activities.push({
                ActivityID: a.ActivityID,
                Lines: [],
                Timeseddel: { CaseFile: 0, SignLink: "", Signed: false }
            })
        }

        const acts: RegActValue[] = (reg.Activities || []).map(a => ({
            ActivityID: a.ActivityID,
            Timeseddel: a.Timeseddel,
            Lines: (a.Lines || []).map(l => ({
                Day: l.Day,
                Hours: l.Hours,
                Description: l.Description,
                Date: l.Day ? monthToMoment(reg!.Month).date(l.Day) : null
            })),
        }))

        const values: RegValue = { Activities: acts };
        setValues(values);
        timeRegform.setFieldsValue(values);
        setReg({ ...reg });

    }, [curMonth, curPerson, timeRegform]);

    async function updateReg(values: RegValue) {
        if (!curPerson || !reg) return

        console.log(values)

        await actions.registrations.updateLines({
            PersonID: curPerson.PersonID,
            Month: reg.Month,
            Activities: values.Activities.map(a => ({
                ActivityID: a.ActivityID,
                Lines: a.Lines.map(l => ({
                    Day: l.Date ? l.Date.date() : null,
                    Hours: l.Hours,
                    Description: l.Description
                }))
            }))
        });
    }

    function onTimeRegChange(c: any, newValues: RegValue) {
        setSaving(true);
        setHasChanged(true);
        setValues(newValues);
        if (debounce) clearTimeout(debounce)
        setDebounce(
            setTimeout(() => {
                updateReg(newValues)
                setSaving(false);
            }, 500)
        );
    }

    async function onSubmit() {
        if (!curPerson || !reg) return

        await actions.registrations.submit({
            PersonID: curPerson.PersonID,
            Month: reg.Month,
            Activities: values.Activities.map(a => ({
                ActivityID: a.ActivityID,
                Lines: a.Lines.map(l => ({
                    Day: l.Date ? l.Date.date() : null,
                    Hours: l.Hours,
                    Description: l.Description
                }))
            }))
        });
    }

    if (curPerson == null || curMonth == null || reg == null) return <Loader />

    let afregning: Afregning | "allokering" = curPersonOrg?.Afregning || "standardsats";
    if (afregning === "faktisk-lon" && getActivePersonAlloc(reg.Month, (curPerson.Allocations || [])))
        afregning = "allokering"

    const isMobile = width <= 1000;

    return (
        <Col flex="0 1 800px">
            <PersonHeader
                onMonthSelect={m => m ? navigate(`/registrering/${curPerson.Email}/${formatMonth(m)}`) : navigate(`/registrering/${curPerson.Email}`)}
                onPersonSelect={p => p ? navigate(`/registrering/${p.Email}/${formatMonth(curMonth)}`) : navigate("/registrering")}
                onOrgClick={o => navigate(`/organisation/${o.CVR}`)}
                showMonth
                showMissingMonths
            />
            {
                roles.includes("controller") && reg.Status !== "missing" &&
                <Row justify="center" style={{ marginTop: 20 }}>
                    <Link to={`/controlling/registrering/${params.email}/${params.month}`}>
                        <Button type="primary">Gå til controlling</Button>
                    </Link>
                </Row>
            }

            <Row align="middle" style={{ marginTop: 10 }}>
                <Col flex="1 0 0">
                    <Row align="middle">
                        <Link to={`/registrering/${curPerson.Email}`}>
                            <Button style={{ padding: 0 }} size="small" type="link">
                                <Row align="middle">
                                    <BiArrowBack size={isMobile ? 18 : 14} style={{ marginRight: 5, marginBottom: -2 }} /> {!isMobile && "Overblik"}
                                </Row>
                            </Button>
                        </Link>
                    </Row>
                </Col>
                <Col flex="1 0 auto">
                    <Row justify="center" align="middle">
                        <h1 style={{ fontSize: 22, margin: 0 }}>{monthToMoment(reg.Month).format("MMMM YYYY")}</h1>
                    </Row>
                </Col>
                <Col flex="1 0 0">
                    <Row justify="end" align="middle">
                        <Button type="link" size="small" onClick={() => setShowLog(true)} >
                            <Row align="middle">
                                {!isMobile && "Historik"} <BiHistory size={isMobile ? 18 : 14} style={{ marginLeft: 5, marginBottom: -2 }} />
                            </Row>
                        </Button>
                    </Row>
                </Col>

            </Row>
            <Row justify="center" style={{ margin: "20px 0 0 0" }}>
                <Col flex="0 0 500px">
                    <StatusAlert reg={reg} />
                </Col>
            </Row>
            <Drawer visible={showLog} onClose={() => setShowLog(false)} title="Historik">
                <RegLog reg={reg} />
            </Drawer>
            {
                afregning === "allokering" ?
                    <div style={{ textAlign: "center" }}>
                        <i>Da du er på allokeringskontrakt, skal der ikke udføres eksplicit timeregistrering.<br /></i>
                        {(reg.Status == "missing" || reg.Status == "rejected") && <i>Du skal blot trykke på &ldquo;Indsend&ldquo; og vedhæfte din lønseddel</i>}
                    </div>
                    :
                    <Form form={timeRegform} initialValues={values || {}} onValuesChange={onTimeRegChange}>
                        {(reg.Activities || []).map((a, i) => <TimeRegAct key={a.ActivityID} idx={i} regAct={a} locked={isLocked} showHeader={(reg.Activities || []).length > 1} />)}
                        {(reg.Activities || []).length == 0 && <Row justify="center"><i style={{ color: "rgba(0, 0, 0, 0.4)" }}>Du deltager ikke i nogen aktiviteter</i></Row>}
                    </Form>
            }
            <Row justify="center" style={{ marginTop: 30 }} gutter={10}>
                <Col>
                    <Button onClick={
                        async () => {
                            timeRegform.validateFields()
                                .then(() => setShowSubmit(true))
                                .catch(() => message.error("Ret fejlene før du indsender"))
                        }
                    } disabled={isLocked} type="primary" icon={<BiLock size={18} style={{ marginBottom: -3, marginRight: 5 }} />}>Indsend</Button>
                </Col>
                <Col>
                    <Button onClick={() => setShowContact(true)} icon={<BiCommentDetail size={18} style={{ marginBottom: -5, marginRight: 5 }} />}>Kontakt os</Button>
                </Col>
            </Row>
            <Row justify="center" style={{ marginTop: 20, fontStyle: "italic", color: "rgba(0, 0, 0, 0.4)" }} align="middle">
                {saving ? "Gemmer..." : "Gemt"}
            </Row>


            <RegContactModal
                visible={showContact}
                onClose={() => setShowContact(false)}
            />

            <RegSubmitModal
                visible={showSubmit}
                onClose={() => setShowSubmit(false)}
                afregning={afregning}
                reg={reg}
                values={values}
                onSubmit={onSubmit}
            />

        </Col >
    )
}