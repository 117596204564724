import { Col, Dropdown, Layout, Menu, Row } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BiBuildings, BiChalkboard, BiChart, BiCheckShield, BiCodeBlock, BiData, BiMenu, BiSpreadsheet } from "react-icons/bi";
import { useOrganisations, useRoles, useUser } from "../../utils/store";
import { useWindowWidth } from "../../utils/utils";
import Logo from "../shared/Logo";

export default function MenuLayout({ children }: any) {
    const roles = useRoles();
    const location = useLocation();
    const [page, setPage] = useState("none");
    const width = useWindowWidth();

    const user = useUser();
    const orgs = useOrganisations();

    useEffect(() => { setPage(location.pathname.split("/")[1]); }, [location.pathname])

    if (!user || !orgs) return null;

    function makeMenu(theme: "dark" | "light") {
        if (!user || !orgs) return <></>;

        return (
            <Menu key={page} mode="vertical" theme={theme} defaultSelectedKeys={[page]} activeKey={page} triggerSubMenuAction="click">
                {roles.includes("admin") &&
                    <Menu.Item key="admin">
                        <Link to="/admin">
                            <Row align="middle"><BiCodeBlock style={{ marginRight: 5 }} /> Admin</Row>
                        </Link>
                    </Menu.Item>
                }
                {roles.includes("program-lead") &&
                    <Menu.Item key="program">
                        <Link to="/program">
                            <Row align="middle"><BiChart style={{ marginRight: 5 }} /> Programoverblik</Row>
                        </Link>
                    </Menu.Item>
                }
                {roles.includes("controller") &&
                    <Menu.Item key="controlling">
                        <Link to="/controlling">
                            <Row align="middle"><BiCheckShield style={{ marginRight: 5 }} />Controlling</Row>
                        </Link>
                    </Menu.Item>
                }
                {roles.includes("project-manager") &&
                    <>
                        <Menu.Item key="projekt">
                            <Link to="/projekt">
                                <Row align="middle"><BiChalkboard style={{ marginRight: 5 }} /> Projektoverblik</Row>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="stamdata">
                            <Link to="/stamdata">
                                <Row align="middle"><BiData style={{ marginRight: 5 }} /> Stamdata</Row>
                            </Link>
                        </Menu.Item>
                    </>
                }

                {orgs.length > 0 &&
                    <Menu.Item key="organisation">
                        <Link to="/organisation">
                            <Row align="middle"><BiBuildings style={{ marginRight: 5 }} /> Organisation</Row>
                        </Link>
                    </Menu.Item>
                }
                <Menu.Item key="registrering">
                    <Link to={`/registrering/${user.Email}`}>
                        <Row align="middle" ><BiSpreadsheet style={{ marginRight: 5 }} /> Registrering</Row>
                    </Link>
                </Menu.Item>
            </Menu >
        );
    }

    return (
        <Layout style={{ minHeight: "100vh" }}>
            {width < 1200 ?
                <div style={{ height: 60 }}>
                    <Row style={{ top: 0, left: 0, right: 0, padding: 10, background: "white", boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", position: "fixed", zIndex: 99 }} align="middle" justify="space-between">
                        <Col flex="1">
                            <Row align="middle">
                                <Dropdown overlay={makeMenu("light")} trigger={['click']} destroyPopupOnHide>
                                    <BiMenu size={38} style={{ cursor: "pointer" }} />
                                </Dropdown>
                            </Row>
                        </Col>
                        <Col flex="4">
                            <Row justify="center" align="middle"><Logo /></Row>
                        </Col>
                        <Col flex="1"></Col>
                    </Row>
                </div>
                :
                <Layout.Sider>
                    <Row justify="center"><Link to="/"><span style={{ cursor: "pointer" }}><Logo light size="medium" /></span></Link></Row>
                    {makeMenu("dark")}
                </Layout.Sider>
            }
            <Layout style={{ background: "white" }}>
                <Layout.Content style={{ padding: 20 }}>
                    <Row justify="center">
                        {children}
                    </Row>
                </Layout.Content>
            </Layout>
        </Layout>
    )
}