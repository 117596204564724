import { Button, Col, Row, Table, TableColumnsType, Tag } from "antd";
import { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { BiCloudDownload, BiPen, BiRefresh } from "react-icons/bi";

import moment from "moment";
import { actions, useCurPerson, useRoles } from "../../../../utils/store";
import { getActivePersonAlloc, momentToMonth, monthToMoment } from "../../../../utils/utils";
import EmployeeAllocAddModal from "./EmpAllocAddModal";
import FakeSignButton from "../../../shared/FakeSignButton";


type Props = {
  visible: boolean
  onClose: () => any
};

export default function EmployeeAllocModal(props: Props) {
  const roles = useRoles();
  const curPerson = useCurPerson();
  const [showAdd, setShowAdd] = useState(false);

  if (!curPerson) return null;

  function onRefresh() {
    actions.persons.get();
  }

  const columns: TableColumnsType<Allocation> = [
    {
      title: "Start",
      sorter: (a, b) => moment(a.Start).diff(moment(b.Start)),
      defaultSortOrder: "ascend",
      render: (v, o) => {
        return `${moment(o.Start).format("DD. MMMM YYYY")}`
      },
    },
    {
      title: "Allokering",
      render: (v, o) => {
        const percentage = o.Activities?.reduce((p, c) => p + c.Alloc, 0);
        return `${percentage || "-"} %`
      }
    },
    {
      title: "Status",
      render: (v, o) => {
        const alloc = getActivePersonAlloc(momentToMonth(moment()), curPerson.Allocations || []);

        return (
          <Row justify="space-between">
            <Col>
              {
                !o.Contract?.Signed
                  ?
                  <>
                    <a href={o.Contract?.SignLink!} target="_blank" rel="noreferrer">
                      <Tag style={{ cursor: "pointer", width: "100%" }} color="blue">
                        <Row align="middle" justify="center"><BiPen style={{ marginRight: 5 }} /> Underskriv</Row>
                      </Tag>
                    </a>
                    {roles.includes("test") && <FakeSignButton doc={o.Contract} />}
                  </>
                  :
                  (alloc === o ? <Tag color="green">Aktiv</Tag> : <Tag title="Allokeringen er endnu ikke startet">Inaktiv</Tag>)
              }
            </Col>
            <Col>
              {
                !o.Contract?.Signed && <Button type="link" size="small" onClick={onRefresh}><BiRefresh size={18} /></Button>
              }
            </Col>
          </Row>
        )
      }
    }
  ];

  return (
    <Modal destroyOnClose visible={props.visible} title={`Allokering - ${curPerson.FullName}`} onCancel={props.onClose} footer={null}>
      <Table size="small" columns={columns} dataSource={curPerson.Allocations || []} pagination={false} />
      <Row justify="center" style={{ marginTop: 20 }}>
        <Button onClick={() => setShowAdd(true)}>Tilføj</Button>
      </Row>

      <EmployeeAllocAddModal visible={showAdd} onClose={() => setShowAdd(false)} />
    </Modal>
  );
}